import { type MaybeRefOrGetter, type Ref, computed, toValue } from 'vue'

import { type ChartData } from '../components/SpiderChart/types'
import type { ModelCharacteristics } from '../models/model-characteristics'
import { findCommonStrings } from '../utils/findCommonStrings'

function getCommonCategoriesWithRatings(
  modelsCharacteristicsList: Array<ModelCharacteristics>,
) {
  // For each model, we get the list of categories that have a rating
  const modelCategoriesWithRatings = modelsCharacteristicsList.map((ch) =>
    ch.groupedCharacteristics.filter((c) => !!c.rating).map((c) => c.category),
  )

  return findCommonStrings(modelCategoriesWithRatings)
}

export function mapModelsCharacteristicsToChartData(
  modelsCharacteristicsList: Array<ModelCharacteristics>,
): ChartData {
  const commonCategoriesWithRatings = getCommonCategoriesWithRatings(
    modelsCharacteristicsList,
  )

  // Ratings are values between 0-100
  const indicators = commonCategoriesWithRatings.map((category) => ({
    name: category,
    max: 100,
  }))

  const chartDataValues = modelsCharacteristicsList.map((characteristics) => {
    const modelRatingsForEachCategory = commonCategoriesWithRatings.map(
      (category) =>
        characteristics.groupedCharacteristics.find(
          (ch) => ch.category === category,
        )?.rating as number,
    )

    return {
      value: modelRatingsForEachCategory,
      name: characteristics.title || '',
    }
  })

  return {
    indicators,
    data: chartDataValues,
  }
}

export function useChartData(
  modelsCharacteristicsList: MaybeRefOrGetter<Array<ModelCharacteristics>>,
): Ref<ChartData> {
  return computed(() => {
    const modelsCharacteristicsListV = toValue(modelsCharacteristicsList)

    return mapModelsCharacteristicsToChartData(modelsCharacteristicsListV)
  })
}
