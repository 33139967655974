import type {
  MultipleQueriesQuery,
  SearchOptions,
} from '@algolia/client-search'
import type {
  ModelComparisonCompareModels,
  ModelComparisonModel,
} from '@backmarket/http-api/src/api-specs-model-comparison/models/compare-models'
import type { AlgoliaProductHit } from '@backmarket/nuxt-layer-cms/products/algolia/types'
import {
  type AlgoliaSearchParams,
  type InitializableAlgoliaClient,
  useAlgoliaWithDeps,
} from '@backmarket/nuxt-layer-cms/products/algolia/useAlgolia'

import type { AlgoliaComparableModelsIndexHit } from '../utils/algolia/comparable-model-algolia-hit'

type TransformQueriesFn = (
  queries: MultipleQueriesQuery[],
) => MultipleQueriesQuery[]

export function useAlgoliaSearchWithQueriesTransformation<T>({
  initializableAlgoliaClient,
}: {
  initializableAlgoliaClient: InitializableAlgoliaClient<T>
}) {
  const { search } = useAlgoliaWithDeps({ initializableAlgoliaClient })

  function searchWithQueriesTransform(
    params: AlgoliaSearchParams & {
      transformQueriesFn?: TransformQueriesFn
    },
  ) {
    const { apiKey, queries, transformQueriesFn } = params
    const transformedQueries = transformQueriesFn
      ? transformQueriesFn(queries)
      : queries

    return search({
      apiKey,
      queries: transformedQueries,
    })
  }

  return {
    search: searchWithQueriesTransform,
  }
}

function replaceModelNameInFilters<ExtraParams extends object>({
  params,
  model,
}: {
  model: { catalogName: string; compareName: string }
  params: {
    facetFilters: (string | string[])[]
  } & ExtraParams
}) {
  const fixedFacetFilters = params.facetFilters.map((filter) =>
    Array.isArray(filter)
      ? filter
      : filter
          .replace('CATALOG_NAME', model.catalogName)
          .replace('COMPARE_NAME', model.compareName),
  )

  return {
    ...params,
    facetFilters: fixedFacetFilters,
  }
}

const replaceModelNameInFiltersTransformer =
  (model: ModelComparisonModel) => (queries: MultipleQueriesQuery[]) => {
    return queries.map((q) => {
      const paramsWithModelName = replaceModelNameInFilters({
        model,
        params: q.params as SearchOptions & {
          facetFilters: (string | string[])[]
        },
      })

      return {
        indexName: q.indexName,
        params: paramsWithModelName,
      }
    })
  }

export function useModelsComparisonAlgoliaSearch(
  initializableAlgoliaClient: InitializableAlgoliaClient<
    AlgoliaProductHit | AlgoliaComparableModelsIndexHit
  >,
) {
  const { search: searchForOneIndex } =
    useAlgoliaSearchWithQueriesTransformation({
      initializableAlgoliaClient,
    })

  function searchForModel({
    model,
    algoliaConfig,
  }: {
    model: ModelComparisonModel
    algoliaConfig: ModelComparisonCompareModels['algoliaConfig']
  }) {
    const includeModelInFilter = replaceModelNameInFiltersTransformer(model)

    const comparison = algoliaConfig.comparison || []

    return Promise.all(
      comparison.map(({ apiKey, queries }) => {
        return searchForOneIndex({
          apiKey,
          queries,
          transformQueriesFn: includeModelInFilter,
        })
      }),
    )
  }

  function searchForAllModels(comparisonResults: ModelComparisonCompareModels) {
    return Promise.all(
      comparisonResults.models.map(async (model) => {
        const algoliaData = await searchForModel({
          model,
          algoliaConfig: comparisonResults.algoliaConfig,
        })

        return { algoliaData, model }
      }),
    )
  }

  return { search: searchForAllModels }
}
