export default {
  characteristicsReviews: {
    id: 'product_comparison_reviews_title',
    defaultMessage: 'Customer reviews',
  },
  characteristicsReviewsGlobalLabel: {
    id: 'product_comparison_reviews_global_label',
    defaultMessage: 'Average',
  },
  characteristicsNote: {
    id: 'product_comparison_reviews_note',
    defaultMessage: '{value}/{total}',
  },
  characteristicsReviewOverallPerformance: {
    id: 'product_comparison_reviews_characteristics_review_overall_performance',
    defaultMessage: 'Performances',
  },
  characteristicsReviewAestheticAppearance: {
    id: 'product_comparison_reviews_characteristics_review_aesthetic_appearance',
    defaultMessage: 'Design',
  },
  characteristicsReviewAccessories: {
    id: 'product_comparison_reviews_characteristics_review_accessories',
    defaultMessage: 'Accessories',
  },
  characteristicsReviewBattery: {
    id: 'product_comparison_reviews_characteristics_review_battery',
    defaultMessage: 'Battery',
  },
  characteristicsReviewCamera: {
    id: 'product_comparison_reviews_characteristics_review_camera',
    defaultMessage: 'Camera',
  },
  characteristicsReviewPackagingCleanliness: {
    id: 'product_comparison_reviews_characteristics_review_packaging_cleanliness',
    defaultMessage: 'Packaging cleanliness',
  },
  characteristicsReviewShipping: {
    id: 'product_comparison_reviews_characteristics_review_shipping',
    defaultMessage: 'Shipping',
  },
  characteristicsAudio: {
    id: 'product_comparison_characteristics_audio',
    defaultMessage: 'Audio',
  },
  characteristicsAudioJackSocket: {
    id: 'product_comparison_characteristics_audio_jack_socket',
    defaultMessage: '3.5mm jack socket',
  },
  characteristicsAudioNumberOfMicrophones: {
    id: 'product_comparison_characteristics_audio_number_of_microphones',
    defaultMessage: 'Number of microphones',
  },
  characteristicsAudioRadio: {
    id: 'product_comparison_characteristics_audio_radio',
    defaultMessage: 'Radio',
  },
  characteristicsAudioStereoSpeaker: {
    id: 'product_comparison_characteristics_audio_stereo_speaker',
    defaultMessage: 'Stereo speakers',
  },
  characteristicsBattery: {
    id: 'product_comparison_characteristics_battery',
    defaultMessage: 'Battery',
  },
  characteristicsBatteryRemovable: {
    id: 'product_comparison_characteristics_battery_removable',
    defaultMessage: 'Removable battery',
  },
  characteristicsBatteryBatteryLife: {
    id: 'product_comparison_characteristics_battery_battery_life',
    defaultMessage: 'Battery life',
  },
  characteristicsBatteryFastCharging: {
    id: 'product_comparison_characteristics_battery_fast_charging',
    defaultMessage: 'Fast charging',
  },
  characteristicsBatteryPower: {
    id: 'product_comparison_characteristics_battery_power',
    defaultMessage: 'Battery power',
  },
  characteristicsBatteryCapacity: {
    id: 'product_comparison_characteristics_battery_capacity',
    defaultMessage: 'Battery capacity',
  },
  characteristicsBatteryUltraPowerSavingMode: {
    id: 'product_comparison_characteristics_battery_ultra_power_saving_mode',
    defaultMessage: 'Ultra power saving mode',
  },
  characteristicsBatteryWirelessCharging: {
    id: 'product_comparison_characteristics_battery_wireless_charging',
    defaultMessage: 'Wireless charging',
  },
  characteristicsCamera: {
    id: 'product_comparison_characteristics_camera',
    defaultMessage: 'Camera',
  },
  characteristicsCameraFlash: {
    id: 'product_comparison_characteristics_camera_flash',
    defaultMessage: 'Flash',
  },
  characteristicsCameraMultiLens: {
    id: 'product_comparison_characteristics_camera_multi_lens',
    defaultMessage: 'Multi lens',
  },
  characteristicsCameraOpticalStabilization: {
    id: 'product_comparison_characteristics_camera_optical_stabilization',
    defaultMessage: 'Optical stabilization',
  },
  characteristicsCameraOpticalZoom: {
    id: 'product_comparison_characteristics_camera_optical_zoom',
    defaultMessage: 'Optical zoom',
  },
  characteristicsCameraPixelsBack: {
    id: 'product_comparison_characteristics_camera_pixels_back',
    defaultMessage: 'Main camera resolution',
  },
  characteristicsCameraPixelsFront: {
    id: 'product_comparison_characteristics_camera_pixels_front',
    defaultMessage: 'Front camera resolution',
  },
  characteristicsCameraSlowMotionVideo: {
    id: 'product_comparison_characteristics_camera_slow_motion_video',
    defaultMessage: 'Slow motion video',
  },
  characteristicsCameraVideoResolution: {
    id: 'product_comparison_characteristics_camera_video_resolution',
    defaultMessage: 'Main camera video resolution',
  },
  characteristicsDesign: {
    id: 'product_comparison_characteristics_design',
    defaultMessage: 'Design',
  },
  characteristicsDesignHeight: {
    id: 'product_comparison_characteristics_design_height',
    defaultMessage: 'Height',
  },
  characteristicsDesignThickness: {
    id: 'product_comparison_characteristics_design_thickness',
    defaultMessage: 'Thickness',
  },
  characteristicsDesignVolume: {
    id: 'product_comparison_characteristics_design_volume',
    defaultMessage: 'Volume',
  },
  characteristicsDesignWaterResistant: {
    id: 'product_comparison_characteristics_design_water_resistant',
    defaultMessage: 'Water resistant',
  },
  characteristicsDesignWeight: {
    id: 'product_comparison_characteristics_design_weight',
    defaultMessage: 'Weight',
  },
  characteristicsDesignWidth: {
    id: 'product_comparison_characteristics_design_width',
    defaultMessage: 'Width',
  },
  characteristicsPerformances: {
    id: 'product_comparison_characteristics_performances',
    defaultMessage: 'Performances',
  },
  characteristicsPerformances5gSupport: {
    id: 'product_comparison_characteristics_performances_5G_support',
    defaultMessage: '5G support',
  },
  characteristicsPerformancesCpuSpeed: {
    id: 'product_comparison_characteristics_performances_CPU_speed',
    defaultMessage: 'CPU frequency',
  },
  characteristicsPerformancesRam: {
    id: 'product_comparison_characteristics_performances_RAM',
    defaultMessage: 'RAM capacity',
  },
  characteristicsPerformancesBodySar: {
    id: 'product_comparison_characteristics_performances_body_SAR',
    defaultMessage: 'Body SAR',
  },
  characteristicsPerformancesExternalMemorySlot: {
    id: 'product_comparison_characteristics_performances_external_memory_slot',
    defaultMessage: 'External memory slot',
  },
  characteristicsPerformancesHeadSar: {
    id: 'product_comparison_characteristics_performances_head_SAR',
    defaultMessage: 'Head SAR',
  },
  characteristicsPerformancesIntegratedLte: {
    id: 'product_comparison_characteristics_performances_integrated_LTE',
    defaultMessage: 'LTE support',
  },
  characteristicsPerformancesProcessorsCore: {
    id: 'product_comparison_characteristics_performances_processors_core',
    defaultMessage: 'Number of processor cores',
  },
  characteristicsPerformancesStorage: {
    id: 'product_comparison_characteristics_performances_storage',
    defaultMessage: 'Storage',
  },
  characteristicsScreen: {
    id: 'product_comparison_characteristics_screen',
    defaultMessage: 'Screen',
  },
  characteristicsScreenDensity: {
    id: 'product_comparison_characteristics_screen_density',
    defaultMessage: 'Density',
  },
  characteristicsScreenResolution: {
    id: 'product_comparison_characteristics_screen_resolution',
    defaultMessage: 'Resolution',
  },
  characteristicsScreenSize: {
    id: 'product_comparison_characteristics_screen_screen_size',
    defaultMessage: 'Size',
  },
  yes: {
    id: 'product_comparison_characteristics_boolean_yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'product_comparison_characteristics_boolean_no',
    defaultMessage: 'No',
  },
  characteristicsReviewBatteryHelperText: {
    id: 'product_comparison_reviews_characteristics_review_battery_helper_text',
    defaultMessage:
      '*Refurbished devices sold on Back Market offer at least 80% of this maximum power',
  },
} as const
