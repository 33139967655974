/* eslint-disable max-classes-per-file */

export class MissingBackboxIndexAlgoliaResponse extends Error {
  constructor() {
    super('No Algolia responses came from the backbox index')
  }
}

export class MissingModelsComparisonIndexAlgoliaResponse extends Error {
  constructor() {
    super('No Algolia responses came from the models-comparison index')
  }
}

export class EmptyAlgoliaResponseHits extends Error {
  constructor() {
    super('No hits in the Algolia response from the models-comparison index')
  }
}
