export default {
  formProductPriceIncentive: {
    id: 'product_comparison_product_price_incentive',
    defaultMessage: 'Starting from {price, number, currency}',
  },
  formProductCallout: {
    id: 'product_comparison_product_callout',
    defaultMessage: 'Buy',
  },
}
