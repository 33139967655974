<template>
  <tr
    class="border-static-default-low grid w-full grid-cols-2 gap-x-32 border-b border-solid focus:bg-static-default-hi focus:outline-none"
    tabindex="0"
  >
    <th
      class="caption col-span-2 col-start-1 row-start-1 row-end-auto pt-16 text-left"
      scope="row"
      tabindex="-1"
    >
      {{ label }}
    </th>

    <td
      v-for="value in values"
      :key="value.toString()"
      class="body-2 pb-16 text-left"
      tabindex="-1"
    >
      <template v-if="isReviewRatingValue(value)">
        <ReviewRating v-if="value.count > 0" v-bind="value" />
      </template>
      <span v-else> {{ value }}</span>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import type { ReviewRating as ReviewRatingT } from '@backmarket/nuxt-layer-recommendation/models/product'

import ReviewRating from './ReviewRating.vue'
import type { ModelsComparisonRow } from './models'

defineProps<ModelsComparisonRow>()

const isReviewRatingValue = (
  value: ModelsComparisonRow['values'][number],
): value is ReviewRatingT => typeof value === 'object'
</script>
