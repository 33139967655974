<template>
  <div class="flex flex-col items-center overflow-hidden">
    <RevIllustration
      v-if="image"
      :alt="modelName"
      class="mx-8 my-0 block"
      :height="320"
      :src="image"
      :width="320"
    />

    <RevSkeleton v-else height="320px" shape="rectangle" width="240px" />

    <div class="py-24">
      <div class="body-1-bold">{{ modelName }}</div>

      <div v-if="price" class="text-static-default-mid body-2">
        {{ priceIncentiveMessage }}
      </div>
    </div>

    <RevButton
      v-if="link"
      data-qa="models-comparison-buy-button"
      :to="link"
      :tracking="{
        name: `buy${modelName}`,
      }"
      variant="secondary"
    >
      {{ i18n(translations.formProductCallout) }}</RevButton
    >
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type Link, type Price } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { RevSkeleton } from '@ds/components/Skeleton'

import translations from './ModelPreview.translations'

const i18n = useI18n()

const props = defineProps<{
  modelName: string
  price?: Price
  image: string
  link?: Link
}>()

const priceIncentiveMessage = computed(() =>
  i18n(translations.formProductPriceIncentive, {
    price: props.price?.amount || '',
  }),
)
</script>
