<template>
  <div v-if="data" class="text-static-default-hi">
    <Breadcrumb
      :category="data.category"
      :models-names="data.comparison.modelsNames"
    />

    <RevContainer class="bg-surface-default-low pb-56 md:pb-72">
      <h1 class="heading-1 mt-32 text-center">
        {{ title }}
      </h1>
      <div class="mb-56">
        <ComparisonForm
          :autocomplete-config="data.algoliaConfig.searchBox"
          :models-names="data.comparison.modelsNames"
        />
      </div>

      <ComparisonResults
        :models-characteristics="data.comparison.characteristics"
        :models-names="data.comparison.modelsNames"
      />
    </RevContainer>

    <AdditionalContent :content="data.content" :custom-content-first="true" />
  </div>
</template>

<script lang="ts" setup>
import { showError } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevContainer } from '@ds/components/Container'

import AdditionalContent from '../../components/AdditionalContent/AdditionalContent.vue'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb.vue'
import ComparisonForm from '../../components/ComparisonForm/ComparisonForm.vue'
import ComparisonResults from '../../components/ComparisonResults/ComparisonResults.vue'
import { useFetchModelsComparison } from '../../composables/useFetchModelsComparison'
import { useModelsComparisonHead } from '../../composables/useModelsComparisonHead'

import translations from './ModelsComparisonResults.translations'

const i18n = useI18n()

const { data, error } = await useFetchModelsComparison()

if (error.value)
  showError({
    statusCode: error.value.statusCode,
    statusMessage: error.value.statusMessage,
  })

useModelsComparisonHead(() => data.value?.content)

const title = computed(() =>
  i18n(translations.resultsTitle, {
    firstModel: data.value?.comparison?.modelsNames?.[0],
    secondModel: data.value?.comparison?.modelsNames?.[1],
  }),
)
</script>
