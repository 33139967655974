import type { ReviewRating } from '@backmarket/nuxt-layer-recommendation/models/product'

import { isNotNullNorUndefined } from '~/utils/object/isNotNullNorUndefined'

import {
  type ModelsComparisonRow,
  type ModelsComparisonTable,
} from '../../components/Table/models'
import {
  type GroupedCharacteristics,
  type ModelCharacteristics,
} from '../../models/model-characteristics'
import characteristicsTranslations from '../characteristics.translations'
import { findCommonStrings } from '../findCommonStrings'
import type { I18nFn } from '../i18n.types'

function mapToRows(
  characteristicsList: GroupedCharacteristics[number]['characteristics'][],
): ModelsComparisonRow[] {
  const commonCharacteristicsLabels = findCommonStrings(
    characteristicsList.map((c) => c.map((ch) => ch.label)),
  )

  return commonCharacteristicsLabels.map((characteristicLabel) => {
    const values = characteristicsList
      .map((c) => c.find((ch) => ch.label === characteristicLabel)?.value)
      .filter(isNotNullNorUndefined)

    return {
      values,
      label: characteristicLabel,
    }
  })
}

const isEmptyReviewRating = (reviewRating: ReviewRating) =>
  reviewRating.count === 0

function isEmptyReviewRatingList(reviewRatingList: ReviewRating[]) {
  if (reviewRatingList.length === 0) return true

  return reviewRatingList.every(isEmptyReviewRating)
}
function buildReviewRatingsTable(
  modelCharacteristicsList: ModelCharacteristics[],
  { i18n }: { i18n: I18nFn },
): ModelsComparisonTable | null {
  // Passing { count: 0, average: 0} by default is to make sure
  // that we don't show the second review rating as that of the first model
  // if the first model is missing rating
  const reviewRatings = modelCharacteristicsList.map(
    (m) => m.reviewRating || { count: 0, average: 0 },
  )

  if (isEmptyReviewRatingList(reviewRatings)) return null

  const reviewRatingsTable: ModelsComparisonTable = {
    label: i18n(characteristicsTranslations.characteristicsReviews),
    rows: [
      {
        label: i18n(
          characteristicsTranslations.characteristicsReviewsGlobalLabel,
        ),
        values: reviewRatings,
        isReviewRating: true,
      },
    ],
  }

  return reviewRatingsTable
}

function getCommonCharacteristicsCategoriesBetweenModels(
  groupedCharacteristicsList: GroupedCharacteristics[],
) {
  return findCommonStrings(
    groupedCharacteristicsList.map((c) => c.map((group) => group.category)),
  )
}

export function mapModelCharacteristicsListToComparisonTables(
  modelCharacteristicsList: ModelCharacteristics[],
  { i18n }: { i18n: I18nFn },
): ModelsComparisonTable[] {
  const modelsGroupedCharacteristicsList = modelCharacteristicsList.map(
    (model) => model.groupedCharacteristics,
  )
  const commonCharacteristicsCategories =
    getCommonCharacteristicsCategoriesBetweenModels(
      modelsGroupedCharacteristicsList,
    )

  const reviewRatingsTable = buildReviewRatingsTable(modelCharacteristicsList, {
    i18n,
  })

  const otherCharacteristicsTables = commonCharacteristicsCategories.map(
    (category) => {
      const categoryCharacteristicsForEachModel =
        modelsGroupedCharacteristicsList
          .map((groupedCharacteristics) => {
            return groupedCharacteristics.find(
              (group) => group.category === category,
            )
          })
          .filter(isNotNullNorUndefined)

      const rows = mapToRows(
        categoryCharacteristicsForEachModel.map((ch) => ch.characteristics),
      )

      const isBatteryCategory =
        category === i18n(characteristicsTranslations.characteristicsBattery)

      return {
        rows,
        label: category,
        helperText: isBatteryCategory
          ? i18n(
              characteristicsTranslations.characteristicsReviewBatteryHelperText,
            )
          : undefined,
      }
    },
  )

  if (reviewRatingsTable)
    return [reviewRatingsTable, ...otherCharacteristicsTables]

  return otherCharacteristicsTables
}
